// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LogoSection from "../logo";

const FooterLink = styled(Link)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "rgb(255,115,0)",
  },
  "&:active": {
    color: theme.palette.primary.main,
  },
}));

// ==============================|| LANDING - FOOTER PAGE ||============================== //

const FooterFull = ({ isFull }) => {
  const theme = useTheme();

  const textColor = "white";

  return (
    <Stack display={"flex"} gap={"2rem"} sx={{ bgcolor: "#7958a3" }}>
      {isFull && (
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            mt: { xs: 0, md: 2 },
            pt: 8,
            pb: { xs: 2.5, md: 10 },
            "&:after": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "80%",
              bottom: 0,
              left: 0,
            },
          }}
        >
          <Container>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{ position: "relative", zIndex: 1 }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      pr: 0,
                      textAlign: "left",
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <LogoSection />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}

      <Box sx={{ pt: isFull ? 0 : 10, pb: 10 }}>
        <Container>
          {/* <Grid container spacing={2}> */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={6} sm={3}>
                <Stack spacing={{ xs: 3, md: 5 }}>
                  <Typography
                    variant="h5"
                    color={textColor}
                    sx={{ fontWeight: 500 }}
                  >
                    About us
                  </Typography>
                  <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                    <FooterLink
                      href="https://www.rotpc.com/"
                      target="_blank"
                      underline="none"
                    >
                      Radcliffe on Trent
                    </FooterLink>
                    <FooterLink
                      href="https://www.facebook.com/rot.knitting/"
                      target="_blank"
                      underline="none"
                    >
                      Facebook
                    </FooterLink>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Stack spacing={{ xs: 3, md: 5 }}>
                  <Typography
                    variant="h5"
                    color={textColor}
                    sx={{ fontWeight: 500 }}
                  >
                    Help
                  </Typography>
                  <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                    <FooterLink
                      component={RouterLink}
                      to="/contact"
                      underline="none"
                    >
                      FAQ
                    </FooterLink>
                    <FooterLink
                      component={RouterLink}
                      to="/contact"
                      underline="none"
                    >
                      Contact form
                    </FooterLink>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Stack spacing={{ xs: 3, md: 5 }}>
                  <Typography
                    variant="h5"
                    color={textColor}
                    sx={{ fontWeight: 500 }}
                  >
                    Partners
                  </Typography>
                  <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                    <FooterLink
                      href="https://knitnottingham.co.uk/"
                      target="_blank"
                      underline="none"
                    >
                      Knit Nottingham
                    </FooterLink>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Container>
      </Box>
    </Stack>
  );
};

export default FooterFull;
