import { Box, Typography } from "@mui/material";
import { UnderConstruction } from "../components/cards/UnderConstruction";

export const Contact = () => {
  return (
    <>
      <UnderConstruction />
    </>
  );
};
