import MainLayout from "../layouts/MainLayout";
import { About } from "../pages/About";
import { Contact } from "../pages/Contact";
import { Home } from "../pages/Home";
import { NotFound } from "../pages/NotFound";
import { Projects } from "../pages/Projects";
import { Schedule } from "../pages/Schedule";

export const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <Home />,
    },
    {
      path: "About",
      element: <About />,
    },
    {
      path: "Projects",
      element: <Projects />,
    },
    {
      path: "Schedule",
      element: <Schedule />,
    },
    {
      path: "Contact",
      element: <Contact />,
    },

    //other paths for future extensions
  ],
};

export const FallbackRoute = { path: "*", element: <NotFound /> };
