import { useMemo } from "react";
import Palette from "./palette";
// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import componentsOverride from "./overrides";

export default function ThemeCustomization({ children }) {
  const theme = useMemo(() => Palette(), []);

  const themeOptions = useMemo(
    () => ({
      typography: {
        fontFamily: `"Verdana", "Tahoma", "Roboto", "Helvetica", "Arial", sans-serif`,
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440,
        },
      },
      direction: "ltr",
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
    }),
    [theme.palette]
  );

  const themes = createTheme(themeOptions);

  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
