import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "80vh",
          flexGrow: 1,
          backgroundColor: "white",
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default MainLayout;
