import { createTheme } from "@mui/material/styles";

const Palette = () => {
  return createTheme({
    palette: {
      primary: {
        main: "#7958a3", //blue 03045e  or forest green 3B755F
        light: "#a581d4", //light blue 0077b6 or light forest green AFC6BD
      },
      secondary: {
        main: "#ff7300", //grey
        light: "#fa9542", //light grey
        dark: "#b45201", //dark grey
      },
      header: {
        text: "#FFFFFF",
        hover: "rgb(255, 115, 0)",
      },
      background: { default: "#FFFFFF", paper: "#F9F9F9" }, // white and offwhite
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            height: "24px",
            width: "24px",
          },
        },
      },
    },
  });
};

export default Palette;
