export const meetings2024 = [
  {
    date: new Date("2024-05-19T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-05-22T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-06-12T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-06-16T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-06-26T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-07-03T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-07-07T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-07-10T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-07-21T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-07-31T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-08-11T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-08-21T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-08-28T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-09-04T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-09-11T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-09-18T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-09-25T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-10-02T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-10-06T13:30:00"),
    locationName: "Costa",
  },
];
