import { Box, Typography } from "@mui/material";
// import { useTheme } from "@mui/material";

export const YarnDivider = ({ variant }) => {
  // const theme = useTheme();
  const backgroundImageUrl = `/yarn-divider-${variant ? variant : "2"}.png`;

  const containerStyle = {
    minHeight: "100px",
    maxHeight: "300px",
    width: "100%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${backgroundImageUrl})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "flex-start",
    color: "#ffffff",
  };

  return (
    <Box style={containerStyle}>
      <Typography>Divider</Typography>
    </Box>
  );
};
