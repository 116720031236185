import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";

export const About = () => {
  const theme = useTheme();
  return (
    <>
      <Stack>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            width: "100%",
            padding: "2rem",
          }}
        >
          <Typography variant={"h2"} color={theme.palette.primary.main}>
            This is us
          </Typography>
        </Box>
        <Box style={{ paddingBottom: "2rem" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Box>
              <Typography>
                We are a very friendly bunch of people who like to get together
                for a chat and a bit of knitting, crochet, quilting, or whatever
                floats your boat.
              </Typography>
              <Typography>
                Most of us are local to Radcliffe on Trent but you don't have to
                be to attend the group.
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "0",
              cursor: "pointer",
            }}
            onClick={() =>
              window.open("https://www.facebook.com/rot.knitting/", "_blank")
            }
          >
            <Typography color={theme.palette.primary.main}>
              Find us on Facebook
            </Typography>
            <IconButton color={"primary"}>
              <FacebookIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider variant="middle" />
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Typography variant={"h4"} color={theme.palette.primary.main}>
              Why 'Knitting through the ages'
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Box>
              <Typography>
                Our founder, the late Jean Mugaset, founded this group hoping to
                bring together people of various ages and create a network of
                local craft-like-minded people. Her aim was to help her
                daughter-in-law, who had just moved in the area with very young
                children, integrate in the village and create a friendship group
                where she could meet like-minded people and find other crafties
                that she could reach out to.
              </Typography>
            </Box>
            <Box>
              <Typography>
                Since then, the group has evolved, but at its core it remains a
                place where everyone is welcome regardless of their age, a place
                where they can socialise and make friends and of course exchange
                skills, project tips and ideas.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider variant="middle" />
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Typography variant={"h4"} color={theme.palette.primary.main}>
              Our core crafts
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              width: "100%",
              padding: "2rem",
            }}
          >
            <Box>
              <Typography>
                While we are primarily focused on knitting and crochet, we also
                have many members who enjoy embroidery, quilting and
                hand/machine sewing. Our core organisers actually each have a
                different specialty so if you're worried about your craft not
                being represented in the group, don't worry, we really have a
                bit of everything. We even have spinners that spin their own
                wool!
              </Typography>
            </Box>
            <Box>
              <Typography>
                There's a variety of abilities in the group and you really don't
                need to be any good at it to come join us. If you're at the
                beginning of your journey and are looking for advice and
                support, we can certainly offer that! And if you're a bit more
                experience and are looking for inspiration and challenge, we
                have great ideas for that too!
              </Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
};
