import { Link } from "react-router-dom";

// material-ui
import { Box, ButtonBase } from "@mui/material";
// import { useTheme } from "@mui/material/styles";

// project import
import { ReactComponent as LogoSmall } from "../../assets/images/logo/logo-small.svg";
import { ReactComponent as LogoName } from "../../assets/images/logo/logo-name.svg";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ isIcon, sx, to, contrast, darkBackground = false }) => {
  // const theme = useTheme();

  const fillColor = "#FFFFFF";
  const strokeColor = fillColor;
  return (
    <ButtonBase disableRipple component={Link} to={!to ? "/" : to}>
      {!!isIcon ? (
        <LogoSmall
          style={{
            fill: fillColor,
            stroke: strokeColor,
          }}
        />
      ) : (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"1rem"}
          alignItems={"center"}
        >
          <LogoName
            style={{
              fill: fillColor,
              stroke: strokeColor,
            }}
            className={"MuiSvgIcon"}
          />
          <p>RoT Knitting</p>
        </Box>
      )}
    </ButtonBase>
  );
};

export default LogoSection;
