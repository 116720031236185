import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { useTheme } from "@mui/material";

export const Hero = () => {
  // const theme = useTheme();
  const navigate = useNavigate();
  const backgroundImageUrl = "/home-hero.jpg";

  const containerStyle = {
    minHeight: "400px",
    maxHeight: "800px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${backgroundImageUrl})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "flex-start",
    color: "#ffffff",
  };

  return (
    <Box style={containerStyle}>
      <Box
        pl={"3rem"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          // maxWidth: "800px",
          width: "100%",
          padding: "2rem",
          backgroundColor: "rgb(169,169,169, 0.5)",
        }}
      >
        <Typography fontSize={32} fontWeight={"bold"}>
          Knitting through the ages
        </Typography>

        <Box style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
          <Button
            onClick={() => navigate("/projects")}
            variant={"contained"}
            color={"secondary"}
          >
            Explore our activities
          </Button>
          <Button onClick={() => navigate("/contact")} variant={"contained"}>
            Contact us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
