import { Box, Button, Typography, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import MapIcon from "@mui/icons-material/Map";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useWindowDimensions } from "../../utils/mediaQuery";

const coloursArray = {
  grey: "141,141,141",
  primary: "121,88,163",
  secondary: "255, 115,0",
};
export const LocationCard = ({
  imageName,
  locationName,
  address,
  day,
  timeslot,
  subtitle,
  mapLink,
  secondaryLink,
}) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        width: width > 768 ? "400px" : "100%",
        gap: "1rem",
        padding: "1rem",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: `0 0 6px 6px rgb(${coloursArray["grey"]},0.2)`,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "left",
          backgroundImage: `url(/schedule/${imageName}.jpg)`,
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderRadius: "8px",
        }}
      ></Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "left",
          gap: "1rem",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <BusinessIcon />
          <Typography>{locationName}</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
            cursor: !!mapLink ? "pointer" : "text",
          }}
          onClick={!!mapLink ? () => window.open(mapLink, "_blank") : undefined}
        >
          <LocationOnIcon />
          <Typography
            sx={{
              ":hover": {
                color: !!mapLink ? theme.palette.header.hover : "black",
              },
            }}
          >
            {address}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <CalendarMonthIcon />
          <Typography>{day}</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <AccessTimeIcon />
          <Typography>{timeslot}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
