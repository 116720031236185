import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import HeaderMobile from "./HeaderMobile";
import HeaderDesktop from "./HeaderDesktop";
import "../../App.css";
import { useMemo } from "react";
import LogoSection from "../logo";

export const Header = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("sm"));

  // header content
  const headerContent = useMemo(() => <HeaderDesktop />, []);
  const headerMobile = useMemo(() => <HeaderMobile />, []);

  return (
    <header className="App-header">
      <LogoSection to="/" contrast={true} isIcon={!!matchesXs} />
      <div className="header-links">
        {/* <Link to="/">About us</Link>
        <Link to="/projects">Projects</Link>
        <Link to="/contact">Contact us</Link> */}
        {!matchesXs ? headerContent : headerMobile}
      </div>
    </header>
  );
};
