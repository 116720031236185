import { Box, Button, Typography } from "@mui/material";
import { useWindowDimensions } from "../../utils/mediaQuery";

export const HomeMessage = ({ imageName, text1, text2, button }) => {
  const { width } = useWindowDimensions();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: width < 768 ? "column" : "row",
        justifyContent: "center",
        alignItems: width < 768 ? "center" : "left",
        width: "100%",
        gap: "2rem",
        padding: "2rem",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "left",
          width: width < 768 ? "100%" : "40%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "lightgrey",
        }}
      >
        <img src={`/home/${imageName}.jpg`}></img>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "left",
          gap: "2rem",
          width: width < 768 ? "100%" : "60%",
          padding: "2rem",
        }}
      >
        <Typography>{text1}</Typography>
        <Typography>{text2}</Typography>
        {button && (
          <Button
            variant="contained"
            color={"primary"}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        )}
      </Box>
    </Box>
  );
};
